import { Image } from '@chakra-ui/react'
import React from 'react'
import profile1 from '../../images/profile/1.png' 
import profile2 from '../../images/profile/2.png' 
import profile3 from '../../images/profile/3.png' 
import profile4 from '../../images/profile/4.png' 
import profile5 from '../../images/profile/5.png' 
import profile6 from '../../images/profile/6.png' 
import profile7 from '../../images/profile/7.png' 
import profile8 from '../../images/profile/8.png' 
import profile9 from '../../images/profile/9.png' 
import profile10 from '../../images/profile/10.png' 


const ProfileImage = () => {
    const images = [profile1, profile2, profile3, profile4, profile5, profile6, profile7, profile8, profile9, profile10]
    return (
        <Image
            filter="grayscale(100%)"
            transition="0.5s"
            _hover={{
                filter: "grayscale(0%)"
            }}
            src={images[Math.floor(Math.random() * images.length)]}
            // borderRadius="full"
            boxSize="175px"
        />
    )
}

export default ProfileImage
